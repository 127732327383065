<template>
    <div>
        <carousel 
            :perPage="1"
            :paginationEnabled="false" 
            :loop="true"
            :navigateTo="navigateTo"
        >
            <slide
                v-for="(slide, key) in alertBanner"
                :key="key"
            >
                <div class="flex items-center justify-between px-5">
                    <vs-icon icon="arrow_back_ios" size="small" class=" mr-4 cursor-move" @click="handleBack(key)"></vs-icon>
                    <!-- <div class="block md:flex items-center text-center"> -->
                        <!-- <div class="flex items-center justify-center">
                            <vs-chip class=" mx-2" :transparent="true" :color="slide.alert_type">
                                <span v-if="slide.alert_type === 'danger'">Urgent</span>
                                <span v-if="slide.alert_type === 'primary'">Notice</span>
                                <span v-if="slide.alert_type === 'warning'">Attention</span>
                            </vs-chip> 
                        </div> -->
                        <span @click="handleSelectBanner(slide)" class=" mx-2 text-primary w-full text-center cursor-pointer hover:underline">{{ slide.title }}</span>
                        <!-- <div @click="handleSelectBanner(slide)" class=" ml-5 flex items-center justify-center">
                            <span class="mr-1 text-primary"> Learn More</span> 
                            <vs-icon icon="arrow_forward" size="small" ></vs-icon>
                        </div> -->
                    <!-- </div> -->
                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-move" @click="handleNext(key)"></vs-icon>
                </div>
            </slide>
        </carousel>
        <vs-popup class="learnMorePopup" :title="selectedBanner.title" :fullscreen="isMobileView ? true : false"  :active.sync="learnMorePopupActive" :button-close-hidden="true">
            <div class=" text-center p-5">
                <div class=" hidden md:block"  v-if="selectedBanner.images && selectedBanner.images.desktop"> 
                    <img width="50%" :src="selectedBanner.images && selectedBanner.images.desktop || '' " alt="image">
                </div>
                <div class=" block md:hidden"  v-if=" selectedBanner.images && selectedBanner.images.mobile"> 
                    <img width="50%" :src="selectedBanner.images && selectedBanner.images.mobile || '' " alt="image">
                </div>
                <div class=" text-3xl my-8">{{ selectedBanner.title }}</div>
                <div class=" my-8">{{ selectedBanner.description }}</div>
                <vs-button :color="selectedBanner.alert_type" @click="learnMorePopupActive = false">I Understand</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        alertId: "",
        selectedBanner: {},
        alertBanner: [],
        learnMorePopupActive: false,
        isMobileView: null,
        navigateTo: 0,
    }),
    methods: {
        ...mapActions("storeAlertNotification", [
            "getStoreAlertNotificationDetails",
        ]),
        async fetchAlertDetails() {
            try {
                const res = await this.getStoreAlertNotificationDetails(this.alertId)
                const tempData = [...res.data.data];
                tempData.map(alert => {
                    if(alert.alert_type === "info") alert.alert_type = "primary"
                    if(alert.alert_type === "error") alert.alert_type = "danger"
                    return alert
                })
                this.alertBanner = tempData
                this.$vs.loading.close();
            
            } catch (error) {
                this.$vs.loading.close();
                console.log(error.message)
            }
        },
        handleSelectBanner(details) {
            this.selectedBanner = {...details}
            this.learnMorePopupActive = true;
        },
        myEventHandler() {
            this.isMobileView = window.innerWidth < 768;
        },
        handleBack (index) {
            if(index !== 0 ) this.navigateTo = this.navigateTo - 1
        },
        handleNext (index) {
            if(index !== this.alertBanner.length - 1  ) this.navigateTo = this.navigateTo + 1
        }
    },
    computed: {
        toggleScreenSize: function () {
            if (this.isMobileView) {
                return !this.learnMorePopupActive;
            }
            return true
        }
    },
    created() {
        this.alertId = this.$route.params.alertId
        this.fetchAlertDetails()
    },
    mounted() {
        window.addEventListener("resize", this.myEventHandler);
        this.myEventHandler();
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}
</script>

<style lang="scss" scope>
.learnMorePopup .vs-popup {
  width: 50%;
}

.con-vs-popup.fullscreen .vs-popup {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}
</style>